<template>
    <div>
        <div style="margin-top: 50px;">
            <el-form :inline="true" label-width='150px'>
                <el-form-item label="免培考试次数：">
                    <el-input v-model="form.examNum" placeholder='请输入免培考试次数'></el-input>
                </el-form-item>
                <el-form-item>
                    次
                </el-form-item>
                <el-form-item>
                    <div style="margin-left:50px;">
                        <el-button style="margin-left: 154px;" @click="fromSubmit1" :loading="submitBtnFrom.submitLoading1" type="text">保存</el-button>
                    </div>
                </el-form-item>
            </el-form>
            <el-form :inline="true" label-width='150px'>
                <el-form-item label="考试间隔：">
                    <el-input v-model="form.examTimespace" placeholder='请输入考试间隔'></el-input>
                </el-form-item>
                <el-form-item>
                    天
                </el-form-item>
                <el-form-item>
                    <div style="margin-left:50px;">
                        <el-button style="margin-left: 154px;" @click="fromSubmit2" :loading="submitBtnFrom.submitLoading2" type="text">保存</el-button>
                    </div>
                </el-form-item>
            </el-form>
            <span class="inputBottTitle">从考试开始时间开始计算考试间隔，考试间隔达到设置天数后，才可进行考试。</span>
            <el-form :inline="true" label-width='150px'>
                <el-form-item label="考试弹窗次数：">
                    <el-input v-model="form.examPop" placeholder='请输入考试弹窗次数'></el-input>
                </el-form-item>
                <el-form-item>
                    次
                </el-form-item>
                <el-form-item>
                    <div style="margin-left:50px;">
                        <el-button style="margin-left: 154px;" @click="fromSubmit3" :loading="submitBtnFrom.submitLoading3" type="text">保存</el-button>
                    </div>
                </el-form-item>
            </el-form>
            <span class="inputBottTitle">APP考试过程中，达到设置的弹窗次数后，系统强制交卷。</span>
            <el-form :inline="true" label-width='150px'>
                <el-form-item label="联系电话：">
                    <el-input v-model="form.contactNum" placeholder='请输入联系电话'></el-input>
                </el-form-item>
                <el-form-item>
                    <div style="margin-left:50px;">
                        <el-button style="margin-left: 178px;" @click="fromSubmit4" :loading="submitBtnFrom.submitLoading4" type="text">保存</el-button>
                    </div>
                </el-form-item>
            </el-form>
            <span class="inputBottTitle">可在APP联系我们处显示并拨号。</span>
            <el-form :inline="true" label-width='150px'>
                <el-form-item label="麦克风监控灵敏值：">
                    <el-input v-model="form.microphone" placeholder='请输入麦克风监控灵敏值'></el-input>
                </el-form-item>
                <el-form-item>
                    分贝
                </el-form-item>
                <el-form-item>
                    <div style="margin-left:190px;">
                        <el-button type="text" @click="fromSubmit5" :loading="submitBtnFrom.submitLoading5">保存</el-button>
                    </div>
                </el-form-item>
            </el-form>
            <el-form :inline="true" label-width='150px'>
                <el-form-item label="免培考试期限：">
                    <el-input v-model="form.freeTrainTimer" placeholder='请输入免培考试期限'></el-input>
                </el-form-item>
                <el-form-item>
                    天
                </el-form-item>
                <el-form-item>
                    <div style="margin-left:190px;">
                        <el-button type="text" @click="fromSubmit6" :loading="submitBtnFrom.submitLoading6" style="margin-left: 15px;">保存</el-button>
                    </div>
                </el-form-item>
            </el-form>
            <el-form :inline="true" label-width='150px'>
                <el-form-item label="变更照片间隔：">
                    <el-input v-model="form.updatePhoto" placeholder='请输入变更照片间隔'></el-input>
                </el-form-item>
                <el-form-item>
                    天
                </el-form-item>
                <el-form-item>
                    <div style="margin-left:190px;">
                        <el-button type="text" @click="fromSubmit7" :loading="submitBtnFrom.submitLoading7" style="margin-left: 15px;">保存</el-button>
                    </div>
                </el-form-item>
            </el-form>
            <el-form :inline="true" label-width='150px'>
                <el-form-item label="允许删除单位人员：">
                    <el-select
                        v-model="form.allowDeletion"
                        multiple
                        collapse-tags
                        placeholder="请选择允许删除单位人员"
                        style="width: 202px;"
                        clearable
                    >
                        <el-option
                            v-for="item in allowDeletionArr"
                            :key="item.Id"
                            :label="item.TypeName"
                            :value="item.Id"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <div style="margin-left:50px;">
                        <el-button style="margin-left: 178px;" @click="fromSubmit8" :loading="submitBtnFrom.submitLoading8" type="text">保存</el-button>
                    </div>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
import { getButtonList } from "../../promissionRouter";
import Toolbar from "../../components/Toolbar";
import util from "../../../util/date";
import { getCommonSetting,setCommonSetting,getDeletedStaffsAuthoritySetting,updateDeletedStaffsAuthoritySetting } from "../../api/api"
export default {
    components: { Toolbar },
    data(){
        return {
            buttonList:[],
            form: {
                examNum: '',
                examTimespace: '',
                examPop: '',
                contactNum: '',
                microphone: '',
                freeTrainTimer: '',
                updatePhoto: '',
                allowDeletion: [],
            },
            submitBtnFrom: {
                submitLoading1: false,
                submitLoading2: false,
                submitLoading3: false,
                submitLoading4: false,
                submitLoading5: false,
                submitLoading6: false,
                submitLoading7: false,
                submitLoading8: false,
            },
            allowDeletionArr: []
        }
    },
    methods:{
        fromSubmit1() {
            this.submitBtnFrom.submitLoading1 = true
            var params = this.getParamsFun(2)
            this.formDataSubmit(1,params)
        },
        fromSubmit2() {
            this.submitBtnFrom.submitLoading2 = true
            var params = this.getParamsFun(1)
            this.formDataSubmit(2,params)
        },
        fromSubmit3() {
            this.submitBtnFrom.submitLoading3 = true
            var params = this.getParamsFun(3)
            this.formDataSubmit(3,params)
        },
        fromSubmit4() {
            this.submitBtnFrom.submitLoading4 = true
            var params = this.getParamsFun(4)
            this.formDataSubmit(4,params)
        },
        fromSubmit5() {
            this.submitBtnFrom.submitLoading5 = true
            var params = this.getParamsFun(5)
            this.formDataSubmit(5,params)
        },
        fromSubmit6() {
            this.submitBtnFrom.submitLoading6 = true
            var params = this.getParamsFun(6)
            this.formDataSubmit(6,params)
        },
        fromSubmit7() {
            this.submitBtnFrom.submitLoading7 = true
            var params = this.getParamsFun(7)
            this.formDataSubmit(7,params)
        },
        fromSubmit8() {
            var params = {}
            var newArr = []
            this.form.allowDeletion.forEach(item => {
                var newObj = {}
                newObj.IntValue = 1
                newObj.Id = item
                newArr.push(newObj)
            })
            params = newArr
            this.submitBtnFrom.submitLoading8 = true
            updateDeletedStaffsAuthoritySetting(params).then(res => {
                var result = res.data
                this.submitBtnFrom.submitLoading8 = false
                if(result.Success) {
                    this.$message.success(result.Message)
                } else {
                    this.$message.error(result.Message)
                }
            })
        },
        getParamsFun(e) {
            var params = {Type: e}
            if(e == 1) {
                params.IntValue = this.form.examTimespace
            } else if(e == 2) {
                params.IntValue = this.form.examNum
            } else if(e == 3) {
                params.IntValue = this.form.examPop
            } else if(e == 4) {
                params.StringValue = this.form.contactNum
            } else if(e == 5) {
                params.IntValue = this.form.microphone
            } else if(e == 6) {
                params.IntValue = this.form.freeTrainTimer
            } else if(e == 7) {
                params.IntValue = this.form.updatePhoto
            }
            return params
        },
        formDataSubmit(e,params) {
            var _this = this
            setCommonSetting(params).then((res) => {
                var result = res.data
                if(result.Success) {
                    this.$message.success(result.Message)
                    if(e == 1) {
                        _this.submitBtnFrom.submitLoading1 = false
                    } else if(e == 2) {
                        _this.submitBtnFrom.submitLoading2 = false
                    } else if(e == 3) {
                        _this.submitBtnFrom.submitLoading3 = false
                    } else if(e == 4) {
                        _this.submitBtnFrom.submitLoading4 = false
                    } else if(e == 5) {
                        _this.submitBtnFrom.submitLoading5 = false
                    } else if(e == 6) {
                        _this.submitBtnFrom.submitLoading6 = false
                    } else if(e == 7) {
                        _this.submitBtnFrom.submitLoading7 = false
                    }
                } else {
                    this.$message.error(result.Message)
                }
            })
        },
        getCommonDataFun(e) {
            var _this = this
            var params = {
                type: e
            }
            getCommonSetting(params).then(res => {
                var result = res.data
                if(result.Success) {
                    if(e == 1) {
                        _this.form.examTimespace = result.Response.IntValue
                    } else if(e == 2) {
                        _this.form.examNum = result.Response.IntValue
                    } else if(e == 3) {
                        _this.form.examPop = result.Response.IntValue
                    } else if(e == 4) {
                        _this.form.contactNum = result.Response.StringValue
                    } else if(e == 5) {
                        _this.form.microphone = result.Response.IntValue
                    } else if(e == 6) {
                        _this.form.freeTrainTimer = result.Response.IntValue
                    } else if(e == 7) {
                        _this.form.updatePhoto = result.Response.IntValue
                    }
                }
            })
        },
        getallowDeletFun() {
            var params = {}
            this.form.allowDeletion = []
            getDeletedStaffsAuthoritySetting(params).then(res => {
                var result = res.data
                if(result.Success) {
                    if(result.Response && result.Response.length > 0) {
                        this.allowDeletionArr = result.Response
                        result.Response.forEach(item => {
                            if(item.IntValue > 0) {
                                this.form.allowDeletion.push(item.Id)
                            }
                        });
                    }
                }
            })
        }
    },
    created(){
        this.getCommonDataFun(1)
        this.getCommonDataFun(2)
        this.getCommonDataFun(3)
        this.getCommonDataFun(4)
        this.getCommonDataFun(5)
        this.getCommonDataFun(6)
        this.getCommonDataFun(7)
        this.getallowDeletFun()
    },
    mounted(){
        let routers = window.localStorage.router
        ? JSON.parse(window.localStorage.router)
        : [];
        this.buttonList = getButtonList(this.$route.path, routers);
    }
}
</script>

<style lang="stylus" scoped>
    .el-textarea>>>.el-textarea__inner {
        min-height 150px!important;
    }
    .inputBottTitle {
        font-size: 14px;
        color: #888;
        margin-left: 153px;
        margin-top: -15px;
        display: block;
        padding-bottom: 15px;
    }
</style>